
// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";

// If you want to override variables do it here
@import "variables";

// If you want to add something do it here
@import "custom";


