// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&family=Rubik:wght@300&display=swap');
body{
  // font-family: 'Rubik', sans-serif;
  font-size: 16px;
}
h1, h2, h3, h4, h5 {
  // font-family: 'PT Sans', sans-serif;
  // font-weight: bold;
}

a, a:hover {
    color: #4799eb;
}

.c-dark-theme .nav-tabs {
  border-color: #444;
}

.c-dark-theme .nav-tabs .nav-link.active {
    background-color: #181924;
    border-bottom-color: #4799eb;
}

.c-dark-theme a, .c-dark-theme a:hover {
    color: #4799eb;
}

.c-dark-theme .nav-tabs a.nav-link {
    color: #fff;
}

.c-dark-theme .nav-tabs a.nav-link.active {
    color: #fff;
}

.select-currency .react-autosuggest__suggestions-container {
  background-color: #181924;
}

.text-small {
  font-size: 10px;
}

.moneyInput {
  input {
    font-size: 22px !important;
    font-weight: 600;
  }
}

.moneyInput.md {
  input {
    font-size: 16px !important;
    font-weight: 600;
  }
}

.DateInput_input {
    font-size: 16px !important;
    border-bottom: 2px solid #eee !important;;
}
.DateInput_input__focused {
  border-bottom: 2px solid #eee !important;;
}

.custom-autosuggest {
    position: relative;
    background-color: #ffd;

    .react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open{
      display: block;
    }

    .react-autosuggest__suggestions-container {
        display: none;
        background-color: #fff;
        position: absolute;
        z-index: 2;
        margin-top: 5px;
        left: 0;
        right: 0;
        border: 1px solid #ddd;
        
    }

    


    ul {
        margin: 0;
        padding: 0;
    }

    li {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .react-autosuggest__suggestion {
        display: block;
        cursor: pointer;
        border-bottom: 1px solid #eee;
        padding: 8px
    }

    .react-autosuggest__suggestion--highlighted {
        background-color: #ddd;
    }

    .divider {
        height: 2px
    }
 
}

@media print {
  @page { margin: 0; }
  .c-header-nav, .filterForm , .breadcrumb, .datatable .table-sm, .CDataTable_arrow-position__3dQ_L, .btn{
    display: none;
  }
  .badge {
    background-color: transparent !important;
    color: #000 !important;
    border: none !important;
    padding: 0;
  }

  .c-header-brand  {
    padding: 20px 0;
    text-align: right;
    .c-icon {
      text-align: right;
      height: 55px;
    }
    
  }

  


}

.currency-input {
  input {
    display: block;
    width: 100%;
    height: calc(1.65em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid;
    color: #5c6873;
    background-color: #fff;
    border-color: #e4e7ea;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.currency-display {
  font-weight: 600;
  font-size: 0.9rem;
  &.lg {
    font-size: 1.2rem;
  }
  &.md {
    font-size: 1rem;
  }
  &.xs {
    font-size: 0.6rem;
  }
}


.dropdown {
  &.nav-item {
    a {
     color: rgba(0, 0, 21, 0.5);
    }
    }
  }


  .DateRangePickerInput {
    width: 100%;
  }

  .c-avatar-img {
  height: 40px;
  width: 40px;
}

.form-control {
  font-size: 16px;
}

.stock-detail  {
  width: 50%;
}

.nav-tabs .nav-link {
  font-size: 18px;
  font-weight: 600;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background-color: #fff;
  border-color: #c4c9d0 #c4c9d0 #fff #c4c9d0;
}

.text-success {
  color: $theme-red;
}
.text-danger {
  color:$theme-green;
}
.text-info {
  color:$theme-blue;
}

.table-invoice-summary {
  td {
    padding: 0.30rem 0.75rem;
  }
  .text-xs, .text-xs .currency-display  {
    font-size: 0.8rem !important;
  }
  .text-md, .text-md .currency-display {
    font-size: 0.9rem !important;
  }
  .text-lg , .text-lg .currency-display {
    font-size: 1rem !important;
  }
  .text-xl, .text-xl .currency-display {
    font-size: 1.2rem !important;
  }
  .balance-row td{
    border-top: 1px solid #ced2d8;
  }
}

.react-autosuggest__container {
  .input-group-prepend {
    background-color: #fff;
    border-right: none !important;

    .input-group-text {
      background-color: transparent;
      padding-right: 0;
    }

    &.disabled {
      background-color: #e4e7ea;
    }
  }
  .input-group-append {
    background-color: #fff;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    border-left: none !important;

    &.disabled {
      background-color: #e4e7ea;
    }
  }
  .form-control {
    border-left: none !important;
    border-right: none !important;
    height: auto;
  } 
}
.clickable {
  cursor: pointer;
}


.text-sm {
  font-size: 0.8rem !important;
}
.text-md {
  font-size: 0.9rem !important;
}
.text-lg{
  font-size: 1rem !important;
}
.text-xl{
  font-size: 1.2rem !important;
}

.filter-form-container {
  > * {
    min-width: 200px;
    max-width: 300px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}