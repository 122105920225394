// Variable overrides
$theme-blue:  #3399ff !default;
$theme-yellow:  #f9b115 !default;
$theme-green:  #2eb85c !default;
$theme-red:  #e55353 !default;
$theme-white: #ffffff !default;
.c-app {
    --primary: #3399ff;
    --secondary: #ffb100;
    --theme-light: #ffffff;
    --theme-dark: #000000;
}